import React from "react";
import type { NextPage } from "next";
import Head from "next/head";
import { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import config from "application/config";
import UserContextProvider from "application/context/user-context-provider";
import { useApollo } from "application/graphql/init-apollo-client";
import type { ClientModel } from "application/graphql/get-my-client";
import "react-image-crop/src/ReactCrop.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../styles/globals.scss";
import { useModalStore } from "../store/modal";
import { Dialog } from "ui";

export type AppPage<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type Props = AppProps & {
  Component: AppPage;
  user: ClientModel | null;
};

const NextApp = ({
  Component,
  pageProps: { user, ...otherProps },
}: AppProps<{ user: ClientModel | null }> & {
  Component: AppPage;
  user: ClientModel | null;
}) => {
  const apolloClient = useApollo(
    {
      apiUrl: config.graphqlApiUrl,
      privateApiUrl: config.graphqlInternalApiUrl,
    },
    otherProps
  );

  const modal = useModalStore();

  const getLayout = Component.getLayout ?? ((page) => page);

  const App = getLayout(
    <>
      <Component {...otherProps} />
      <ToastContainer />
      <Dialog
        open={modal.open}
        headerStyle={modal.headerStyle}
        dialogStyle={modal.dialogStyle}
        closeStyle={modal.closeStyle}
        header={modal.header}
        onClose={modal.onClose}
      >
        {modal.content}
      </Dialog>
    </>
  );

  let isDevelop = true;

  if (typeof window !== "undefined") {
    isDevelop =
      process.env.NODE_ENV === "development" ||
      document.location.origin === "https://eventica.labado.ru" ||
      document.location.origin === "http://localhost:3000";
  }

  return (
    <>
      <Head>
        {!isDevelop ? (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-GXN98YH7J4"
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-GXN98YH7J4');
            `,
              }}
            ></script>
          </>
        ) : (
          <></>
        )}

        {!isDevelop ? (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                    m[i].l=1*new Date();
                    for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                 
                    ym(95274451, "init", {
                         clickmap:true,
                         trackLinks:true,
                         accurateTrackBounce:true,
                         webvisor:true
                    });
                    `,
              }}
            />
            <noscript>
              <div>
                <img src="https://mc.yandex.ru/watch/95274451" alt="" />
              </div>
            </noscript>
          </>
        ) : (
          <></>
        )}

        <meta
          name="viewport"
          content="initial-scale=1.0, user-scalable=no, width=device-width"
        />
      </Head>
      <ApolloProvider client={apolloClient}>
        <UserContextProvider user={user}>{App}</UserContextProvider>
      </ApolloProvider>
    </>
  );
};

export default NextApp;
